@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    height: 100%;
    font-family: 'Nunito Sans', sans-serif;
    font-display: swap;
  }
}
